@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Raleway:wght@100&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(237, 237, 237);
  font-family: 'Barlow', sans-serif;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  text-align: center;
}

.container .header {
  margin-bottom: 10px;
}

.inc-exp-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.inc-exp-container > div {
  margin: 10px;
}

.inc-exp-container p {
  margin-top: 5px;
  font-size: 20px;
}

.plus {
  color: green;
}

.minus {
  color: red;
}
.transaction-list {
  margin-bottom: 10px;
}

.transaction-list ul {
  min-height: 300px;
  overflow: scroll;
  width: 300px;
  margin: 0 auto;
}

.transaction-list h3 {
  margin-bottom: 5px;
}

.transaction-list .list li {
  position: relative;
  list-style-type: none;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(205, 203, 203);
  margin-bottom: 5px;
  color: #000000;
  cursor: default;
  padding-left: 35px;
  padding-right: 10px;
}

.transaction-list .list li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
}

.transaction-list .list li.minus:before {
  background-color: red;
}

.transaction-list .list li.plus:before {
  background-color: green;
}

.transaction-list .list li:last-child {
  margin: 0;
}

.transaction-list .delete-transaction {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  background-color: red;
  transition: opacity .2s ease;
  cursor: pointer;
}

.add-transaction h3 {
  margin-bottom: 10px;
}

.add-transaction .form-control {
  margin-bottom: 5px;
}

.add-transaction label {
  min-width: 70px;
  display: inline-block;
  text-align: left;
}

.add-transaction .form {
  margin-bottom: 10px;
}

.add-transaction input {
  outline: none;
  padding: 10px;
}

.add-transaction .btn {
  margin-top: 10px;
}

.btn {
  padding: 10px 15px;
  background-color: lightblue;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}